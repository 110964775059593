<template>
  <main-container :icon="$route.meta.icon" :title="$route.meta.title">

    <EmcTableItems
        :headers="headers"
        v-bind:params.sync="params"
        v-bind:collections.sync="collections"
        v-bind:meta.sync="meta"
        v-bind:itemDelete.sync="itemDelete"
        v-bind:itemEdit.sync="model"
        :loading="loading"
    />

    <template v-slot:tools>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="indigo darken-1" icon @click.prevent.stop="showForm = true" v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
        </template>
        <span> Novo Cadastro </span>
      </v-tooltip>
    </template>

    <SysRegisterPlan @onCompleted="getData()" :model="model" v-bind:show.sync="showForm" />

    <!-- Modal usado para mensagem e ação de exclusão -->
    <emc-alert-modal :text="text" v-bind:show.sync="show">
      <template v-slot:actions>
        <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
      </template>
    </emc-alert-modal>

  </main-container>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'PlanIndex',
  data () {
    return {
      headers: [
        { text: 'Nome', value: 'name', sortable: false},
        { text: 'Criado em', value: 'created_at', sortable: false },
        { text: 'Status', value: 'active', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false}
      ],
      params: {},
      meta: {},
      collections: [],
      itemDelete: {},
      model: { active: false, modules: [], charge_types: [] },
      loading: false,
      message: '',
      error: false,
      errors: {},
      text: '',
      show: false,
      showForm: false,
      titleForm: 'Cadastrar',
      deleting: false,
      updating: false
    }
  },
  methods: {
    ...mapActions('plan', ['ActionIndexPlan', 'ActionDestroyPlan']),
    getData() {

      this.error = false;
      this.message = '';
      this.loading = true;
      this.showForm = false;

      const params = {
        with: 'modules,chargeTypes.type'
      }

      this.ActionIndexPlan(params)
          .then((r) => {
            this.collections = r.data
            this.meta = r.meta
          })
          .finally(() => {
            this.loading = false
          })
    },
    deleteItem() {
      this.deleting = true;
      this.message = '';

      this.ActionDestroyPlan({ id: this.itemDelete.id })
          .then((res) => {
            this.message = res.message;
            this.show = false;
            this.getData();
          })
          .catch((error) =>{
            this.message = error.message;
            this.error = true;
          })
          .finally(() => {
            this.deleting = false;
          });
    }
  },
  watch: {
    params(params_new, params_old){
      if(params_new != params_old) {
        this.getData()
      }
    }, //Função para tratar quando um item for exibido para deletar
    itemDelete(item_new, item_old){
      if(item_new !== item_old && Object.keys(item_new).length > 0)
      {
        this.show = true
        this.text = "Deseja excluir " + item_new.name + "?"
      } else {
        this.show = false
        this.text = ''
      }
    },
    show(show_new, show_old) {
      if(show_new != show_old && !show_new) {
        this.itemDelete = {};
      }
    },
    model(item_new, item_old){
      if(item_new !== item_old && (this.model && this.model.id))
      {
        this.titleForm = 'Editar contato do site'
        this.showForm = true
        this.errors = {}

      } else if(Object.keys(this.model).length === 0){

        this.titleForm = 'Cadastrar';
        this.showForm = false;
        this.errors = {};
      }
    },
    showForm(show_new, show_old) {
      if(show_new != show_old && !show_new) {
        this.model = {
          active: false,
          modules: [],
          charge_types: []
        };
      }
    },
  },
}
</script>